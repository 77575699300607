<template>
    <div id="btmNav">
        <v-bottom-navigation>
            <v-btn
                v-if="$store.state.userProfile.position === 1"
                @click="$emit('openTeamView')"
            >
                <span>チーム管理</span>
                <v-icon>mdi-account-multiple</v-icon>
            </v-btn>

            <v-btn
                @click="
                    $emit('switchRecordPanel');
                    midLabel = !midLabel;
                "
            >
                <span>{{ midLabel ? "打刻履歴" : "戻る" }}</span>
                <v-icon>{{ midLabel ? "mdi-timeline-clock" : "mdi-backspace" }}</v-icon>
            </v-btn>
            <v-btn @click="darkMode">
                <span>{{$vuetify.theme.dark ? "明色" :"暗色"}}</span>
                <v-icon>{{$vuetify.theme.dark ? "mdi-white-balance-sunny" :"mdi-weather-night"}}</v-icon>
            </v-btn>
            <v-btn @click="$store.dispatch('logout')">
                <span>ログアウト</span>
                <v-icon>mdi-logout</v-icon>
            </v-btn>
            
        </v-bottom-navigation>
    </div>
</template>

<script>
export default {
    data() {
        return {
            midLabel: true,
        };
    },
    methods:{
        darkMode() {
            if(!this.$vuetify.theme.dark)
                localStorage.setItem('d', true)
            else
                localStorage.removeItem('d')

            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },
    }
};
</script>

<style>
#btmNav {
    position: fixed;
    bottom: 0px;
    width: 100vw;
}
</style>
