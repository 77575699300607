<template>
    <v-dialog
        :ref="targetProp"
        v-model="display"
        :return-value.sync="data[targetProp]"
        persistent
        width="290px"
    >
        <v-btn
            color="error"
            class="my-1"
            @click="display = false"
        >
            閉じる
        </v-btn>
        <v-btn
            color="success"
            class="my-1"
            @click="display = false; initEmit();"
        >
            確定
        </v-btn>
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="data[targetProp]"
                outlined
                readonly
                dense
                :label="label? label : null"
                v-bind="attrs"
                v-on="on"
                :hide-details="true"
            ></v-text-field>
        </template>
        <v-sheet>
            <v-date-picker
                v-model="tmp.date"
                full-width
                locale="jpn"
            ></v-date-picker>
            <v-time-picker
                v-model="tmp.time"
                full-width
                locale="jpn"
                format="24hr"
            ></v-time-picker>
        </v-sheet>
    </v-dialog>
</template>

<script>
export default {
    props:["view", "targetProp", "data", "label"],
    data:()=>({
        display:false,
        tmp:{
            time:null,
            date:null
        }
    }),
    mounted(){
        this.display = this.view
        this.tmp.date = this.data[this.targetProp].split(" ")[0]
        this.tmp.time = this.data[this.targetProp].split(" ")[1]
    },
    methods:{
        initEmit(){
            let data = this.tmp.date + " " + this.tmp.time
            this.$emit('syncSelectedRecord', {original:this.data, prop:this.targetProp, newValue:data})
        }
    }
};
</script>

<style>
</style>