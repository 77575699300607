<template>
    <div>
        <member-list :headerGenbaKoushu=true />
    </div>
</template>

<script>
import MemberList from '@/components/leaderComponent/MemberList'

export default {
    components:{
        MemberList
    }
}
</script>

<style>

</style>