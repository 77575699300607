<template>
    <div v-if="userStatus">
        <div style="padding-bottom: 5em" v-if="userRecordView">
            <user-record-table :user="$store.state.userProfile.id" :queryMode="true" />
        </div>
        <div v-else class="pb-15">
            <v-card color="primary" >
                <div class="d-flex">
                    <v-card-title class="white--text">
                        <v-chip color="success" class="text-h5 pa-6">
                            {{ $store.state.userProfile.name }}
                        </v-chip>
                    </v-card-title>
                    <v-spacer />
                    <notification-widget />
                </div>
                <v-card-text>
                    <div class="text-center">
                        <img
                            class="rounded"
                            width="80"
                            height="110"
                            :src="imageSrc"
                        />
                    </div>
                    <div class="text-center my-4">
                        <clock class="white--text" />
                    </div>
                    <hr class="my-4" style="opacity: 0" />
                    <v-card
                        dark
                        class="pa-4 mt-4 text-center"
                        style="background: rgba(55, 55, 55, 0.5)"
                    >
                        <div style="weight: bold; font-size: 2em">
                            {{ userStatus }}
                        </div>
                    </v-card>
                    <v-simple-table
                        dark
                        class="my-2"
                        style="background: rgba(55, 55, 55, 0.5)"
                    >
                        <template v-slot:default>
                            <tbody>
                                <tr >
                                    <td>班</td>
                                    <td class="text-h6">{{ $store.state.userProfile.team.name }}</td>
                                </tr>
                                <tr>
                                    <td>現場</td>
                                    <td class="text-h6">
                                        {{ label.genba ? label.genba : "未設定" }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>工種</td>
                                    <td class="text-h6">
                                        {{ label.koushu ? label.koushu : "未設定"}}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        <div></div>
                    </v-simple-table>
                </v-card-text>
            </v-card>

            <v-form
                v-model="formValid.selectGenba"
                v-if="!edit.koushu && userStatus === '退勤中'"
                class="text-center px-3 my-1"
            >
                <v-select
                    v-if="edit.genba"
                    v-model="update.genba"
                    outlined
                    dense
                    class="my-3"
                    label="現場選んでください"
                    :hide-details="true"
                    :items="gData.genba"
                    item-text="genbaName"
                    item-value="genbaCode"
                />
                <div>
                    <v-btn
                        v-if="edit.genba"
                        :disabled="!formValid.selectGenba"
                        class="success"
                        width="100%"
                        @click="updateUserGenba()"
                        >確定</v-btn
                    >
                    <v-btn
                        v-if="edit.genba"
                        text
                        class="error mt-3"
                        width="100%"
                        @click="edit.genba = false"
                        >キャンセル</v-btn
                    >
                </div>
                <v-btn
                    v-if="!edit.genba"
                    class="secondary ma-1 text-h5"
                    style="width: 95%; max-width: 600px"
                    @click="edit.genba = !edit.genba"
                >
                    現場変更
                </v-btn>
            </v-form>
            <v-form
                v-model="formValid.selectKoushu"
                v-if="!edit.genba && userStatus === '退勤中'"
                class="text-center px-3 my-1"
            >
                <v-select
                    v-if="edit.koushu"
                    v-model="update.koushu"
                    outlined
                    dense
                    class="mt-3"
                    label="工種選んでください"
                    :items="gData.koushu"
                    item-text="koushuName"
                    item-value="koushuCode"
                />
                <div>
                    <v-btn
                        v-if="edit.koushu"
                        :disabled="!formValid.selectKoushu"
                        class="success"
                        width="100%"
                        @click="updateUserKoushu()"
                        >確定</v-btn
                    >
                    <v-btn
                        v-if="edit.koushu"
                        text
                        class="error mt-3"
                        width="100%"
                        @click="edit.koushu = false"
                        >キャンセル</v-btn
                    >
                </div>
                <v-btn
                    v-if="!edit.koushu"
                    class="secondary ma-1 text-h5"
                    style="width: 95%; max-width: 600px"
                    @click="edit.koushu = !edit.koushu"
                >
                    工種変更
                </v-btn>
            </v-form>

            <div
                class="text-center"
                v-if="
                    this.$store.state.userProfile.genba && this.$store.state.userProfile.koushu
                "
            >
                <v-btn
                    style="width: 95%; max-width: 600px"
                    class="primary display-1 pa-8 my-2 font-weight-bold"
                    @click="btnAction(0)"
                    v-if="btnStatus[0].status"
                    >{{ btnStatus[0].label ? "出勤" : "退勤" }}</v-btn
                >
                <v-btn
                    style="width: 95%; max-width: 600px"
                    class="primary display-1 pa-8 my-2 font-weight-bold"
                    @click="btnAction(1)"
                    v-if="btnStatus[1].status"
                    >{{ btnStatus[1].label ? "休憩" : "休憩終了" }}</v-btn
                >
                <v-btn
                    style="width: 95%; max-width: 600px"
                    class="primary display-1 pa-8 my-2 font-weight-bold"
                    @click="btnAction(2)"
                    v-if="btnStatus[2].status"
                    >{{ btnStatus[2].label ? "移動" : "移動終了" }}</v-btn
                >
            </div>
            <v-divider />
            <div class="text-center">
                <span class="caption">今月勤務状況</span>
                <current-month-work-time-info-panel />
            </div>
        </div>

        <btm-nav
            @openTeamView="teamView = !teamView"
            @switchRecordPanel="userRecordView = !userRecordView"
        />
        <team-view
            :key="teamViewKey"
            :displayModel="teamView"
            @closeteamview="
                teamView = false;
            "
        />

        <!--Dialog for moving-->
        <v-dialog v-model="idouPanelDisplay" persistent>
            <v-card dark class="pa-4">
                <div style="text-align: center">目的地を選択してください。</div>
                <v-form v-model="formValid.idou">
                    <v-select
                        outlined
                        dark
                        label="現場"
                        v-model="update.genba"
                        :items="gData.genba"
                        item-text="genbaName"
                        item-value="genbaCode"
                    />
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!formValid.idou"
                        color="success"
                        @click="
                            updateUserGenba();
                            submitAction('21');
                        "
                        >移動開始</v-btn
                    >
                    <v-btn @click="idouPanelDisplay = false" color="error"
                        >閉じる</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//TODO:有給状況
import Clock from "@/components/generalComponents/Clock.vue";
import NotificationWidget from "@/components/generalComponents/NotificationWidget.vue";
import BtmNav from "@/components/generalComponents/BtmNav.vue";
import TeamView from "@/views/leader/TeamView.vue";
import UserRecordTable from "@/components/UserRecordTable.vue";
import CurrentMonthWorkTimeInfoPanel from "./currentMonthWorkTimeInfoPanel.vue";
export default {
    components: {
        Clock,
        BtmNav,
        TeamView,
        UserRecordTable,
        NotificationWidget,
        CurrentMonthWorkTimeInfoPanel
    },
    data() {
        return {
            imageSrc:null,
            formValid: {
                selectGenba: false,
                selectKoushu: false,
                idou: false,
            },
            edit: {
                genba: false,
                koushu: false,
            },
            update: {
                genba: this.$store.state.userProfile.genba,
                koushu: this.$store.state.userProfile.koushu,
            },
            label: {
                genba: null,
                koushu: null,
            },
            teamView: false,
            btnStatus: [
                { status: true, label: true }, //status = display, label = method
                { status: false, label: true },
                { status: false, label: true },
            ],
            gData: {
                genba: null,
                koushu: null,
            },
            userStatus:null,
            historyKey: 0,
            userRecordView: false,
            idouPanelDisplay: false,
            teamViewKey: "teamViewKey%0",
        };
    },
    async mounted() {
        await this.getGeneralInfo();
        await this.getUserInfo();
        this.userStatus = this.$store.state.userProfile.status
        this.syncBtnStatus(this.userStatus)
        if(!this.imageExists(`${this.$hostUrl}/img/avatar/x80${this.$store.state.userProfile.id}.jpeg`))
            this.imageSrc = `${this.$hostUrl}/img/avatar/default.jpg`
        else this.imageSrc = `${this.$hostUrl}/img/avatar/x80${this.$store.state.userProfile.id}.jpeg`
    },
    methods: {
        imageExists(image_url){
            let http = new XMLHttpRequest();
            http.open('HEAD', image_url, false);
            http.send();
            return http.status != 404;
        },
        convertCodeToLabel() {
            let genbaList = this.gData.genba;
            let koushuList = this.gData.koushu;
            for (let i = 0; i < genbaList.length; i++)
                if (genbaList[i].genbaCode === this.$store.state.userProfile.genba) {
                    this.label.genba = genbaList[i].genbaName;
                    break;
                }
            for (let i = 0; i < koushuList.length; i++)
                if (koushuList[i].koushuCode === this.$store.state.userProfile.koushu) {
                    this.label.koushu = koushuList[i].koushuName;
                    break;
                }
        },
        async updateUserGenba(){
            let response = await this.$store.dispatch('post',
                {
                    url:'/api/updateUserGeneralInfo',
                    rawData: {
                        key: 0,
                        data: this.update.genba,
                        targetId: this.$store.state.userProfile.id,
                    },
                }
            )
            if(response && response.result){
                this.getUserInfo();
                this.edit.genba = false;
            }
        },
        async updateUserKoushu() {
            let response = await this.$store.dispatch('post',
                {
                    url:'/api/updateUserGeneralInfo',
                    rawData: {
                        key: 1,
                        data: this.update.koushu,
                        targetId: this.$store.state.userProfile.id,
                    },
                }
            )
            if(response && response.result){
                this.getUserInfo();
                this.edit.koushu = !this.edit.koushu;
            }
        },
        async getGeneralInfo() {
            this.gData.genba = await this.$get.getGenba();
            this.gData.koushu = await this.$get.getKoushu();
        },
        async getUserInfo() {
            try {
                if(!this.$store.state.userProfile.id){
                    await this.$store.dispatch("refreshToken")
                }
                const response = await this.$store.dispatch("get", {
                    url: `/api/getUserInfo?uid=${this.$store.state.userProfile.id}`,
                });
                if (!response && !response.result) return
                const result = response.result;
                const data = {
                    status : result.userStatus,
                    genba : result.genbaNkoushu.genba,
                    koushu : result.genbaNkoushu.koushu,
                    team : result.userTeam,
                    position : result.userPosition
                }
                if (result) {
                    this.$store.commit("setUserInfo", data);
                    this.convertCodeToLabel();
                }
            } catch (err) {
                console.log(err);
            }
        },
        isEqual(left, right) {
            if(!left || !right) return false
            if (left.length !== right.length) return false;
            for (var i = 0; i < left.length; i++) {
                if (left[i] !== right[i]) return false;
            }
            return true;
        },
        syncBtnStatus(status) {
            //Determine Btn status
            if (this.isEqual(status, [false, false, false])) {
                this.userStatus = "退勤中";
                this.btnStatus[0].status = true;
                this.btnStatus[0].label = true;
                this.btnStatus[1].status = false;
                this.btnStatus[1].label = true;
                this.btnStatus[2].status = false;
                this.btnStatus[2].label = true;
            } else if (this.isEqual(status, [true, false, false])) {
                this.userStatus = "出勤中";
                this.btnStatus[0].status = true;
                this.btnStatus[0].label = false;
                this.btnStatus[1].status = true;
                this.btnStatus[1].label = true;
                this.btnStatus[2].status = true;
                this.btnStatus[2].label = true;
            } else if (this.isEqual(status, [true, true, false])) {
                this.userStatus = "休憩中";
                this.btnStatus[0].status = false;
                this.btnStatus[0].label = false;
                this.btnStatus[1].status = true;
                this.btnStatus[1].label = false;
                this.btnStatus[2].status = false;
                this.btnStatus[2].label = true;
            } else if (this.isEqual(status, [true, false, true])) {
                this.userStatus = "移動中";
                this.btnStatus[0].status = false;
                this.btnStatus[0].label = false;
                this.btnStatus[1].status = false;
                this.btnStatus[1].label = true;
                this.btnStatus[2].status = true;
                this.btnStatus[2].label = false;
            }
        },
        async btnAction(index) {
            switch (index) {
                case 0: //出勤
                    if (
                        !this.$store.state.userProfile.genba &&
                        !this.$store.state.userProfile.koushu &&
                        this.userStatus === "退勤中"
                    ) {
                        if (
                            !window.confirm(
                                "現場または工種はまだ設定しておりません。続きますか？"
                            )
                        )
                            return;
                    }
                    if (!this.btnStatus[index].label) await this.submitAction("00");
                    else await this.submitAction("01");
                    break;
                case 1: //休憩
                    if (!this.btnStatus[index].label) await this.submitAction("10");
                    else await this.submitAction("11");
                    break;
                case 2: //移動
                    if (!this.btnStatus[index].label) {
                        await this.submitAction("20");
                    } else {
                        this.idouPanelDisplay = true;
                        //this.submitAction("21")
                    }
                    break;
            }
        },
        getCurrentPosition() {
            return new Promise((resolve, reject) => {
                let options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                };
                navigator.geolocation.getCurrentPosition(
                    (pos) => {
                        resolve({
                            latitude: pos.coords.latitude,
                            longitude: pos.coords.longitude,
                        });
                    },
                    () => resolve({
                            latitude: null,
                            longitude: null,
                        }),
                    options
                );
            });
        },
        async submitAction(action) {
                this.$store.commit('setLoadingScreen',{display:true})
                const data = {
                    user: this.$store.state.userProfile.id,
                    method: action,
                    locationData: await this.getCurrentPosition(),
                };
                this.$store.commit('setLoadingScreen',{display:false})
                let response = await this.$store.dispatch('post',
                    {
                        url:'/api/switchUserStatus',
                        showUI:true,
                        rawData: data,
                    }
                )
                if(response && response.result){
                    const rsResponse = response.result;
                    if (rsResponse.authorized === false) {
                        location.reload();
                    }
                    if (rsResponse.check === true) {
                        this.syncBtnStatus(rsResponse.info);
                        this.historyKey += 1;
                    } else {
                        this.getUserInfo();
                    }
                    if (this.idouPanelDisplay) this.idouPanelDisplay = false;
                }
        },
    },
};
</script>
