<template>
    <div class="text-center">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-6" v-bind="attrs" v-on="on" icon @click="setRead">
                    <v-badge 
                        :content="unread" 
                        color="error" 
                        overlap
                        v-model="show"
                    >
                        <v-icon large> mdi-bell </v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <v-list style="height:50vh; overflow-y:scroll">
                <v-list-item
                    v-for="(item, index) in historyData"
                    :key="index"
                >
                    <div>
                        <span class="caption" style="opacity:.5">{{item.date}}</span>
                        <v-list-item-title>{{ item.content }}</v-list-item-title>
                    </div> 
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
    data: () => ({
        historyData: [],
        unread:0,
        show:false
    }),
    methods: {
        setRead(){
            if(this.historyData.length > 0)
            localStorage.setItem('lrt', this.historyData[0].date)
            this.unread = 0
            this.show = false
        },
        async init() {
            const response = await this.$store.dispatch("get", {
                url: `/api/getUserHistory?uid=${this.$store.state.userProfile.id}`,
            });
            if (response && response.result)
                return response.result.history.reverse().slice(0, 20);
        },
        checkUnread(){
            let lastRead = localStorage.getItem("lrt")
            let lastReadCount = 0
            if(!lastRead) lastReadCount = this.historyData.length
            this.historyData.forEach(history => {
                if(dayjs(history.date).isAfter(dayjs(lastRead)))
                    lastReadCount++
            });
            this.unread = lastReadCount
        }
    },
    async mounted() {
        this.historyData = await this.init();
        this.checkUnread()
        if(this.unread > 0) this.show = true
    },
};
</script>
