<template>
    <div>
        <div v-if="queryMode">
            <v-form v-model="formValid.dateSubmit">
                <div>
                    <v-menu
                        ref="menu"
                        v-model="menuModel.menuDate1"
                        :close-on-content-click="false"
                        :return-value.sync="queryDate.from"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="queryDate.from"
                                label="開始"
                                prepend-icon="mdi-calendar"
                                readonly dense
                                :rules="[v=>!!v || '日付を選んでください']"
                                outlined class="mt-4 mx-2"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="queryDate.from"
                            no-title
                            scrollable
                            locale="ja"
                        >
                            <v-spacer/>
                            <v-btn text color="primary" @click="$refs.menu.save(queryDate.from)">確定</v-btn>
                            <v-btn text @click="menuModel.menuDate1 = false">キャンセル</v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-menu
                        ref="menu2"
                        v-model="menuModel.menuDate2"
                        :close-on-content-click="false"
                        :return-value.sync="queryDate.to"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="queryDate.to"
                                label="終了"
                                prepend-icon="mdi-calendar"
                                readonly dense
                                :rules="[v=>!!v || '日付を選んでください']"
                                outlined class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="queryDate.to"
                            no-title
                            scrollable
                            locale="ja"
                        >
                            <v-spacer/>
                            <v-btn text color="primary" @click="$refs.menu2.save(queryDate.to)">確定</v-btn>
                            <v-btn text @click="menuModel.menuDate2 = false">キャンセル</v-btn>
                        </v-date-picker>
                    </v-menu>
                    <div class="pa-2 text-center">
                        <v-btn class="mx-1" color="primary" :disabled="!formValid.dateSubmit" @click="init()">検索</v-btn>
                        <v-btn class="mx-1 primary" @click="initThisMonth();init();">今月分</v-btn>
                        <v-btn class="mx-1 success" @click="initNewRecordDialog">新規申請</v-btn>
                    </div>
                </div>
            </v-form>
        </div>
        <v-simple-table v-if="recordData" > 
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            日付
                        </th>
                        <th class="text-left">
                            稼働時間
                        </th>
                        <th class="text-left">
                            詳細
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="record in recordData"
                        :key="record.recordId"
                    >
                        <td>{{ record.recordStart }}</td>
                        <td>
                            {{ $gf.convertTime(record.totalTime) }}
                        </td>
                        <td>
                            <v-btn tile text @click="initInfoDialog(record)">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <!--Dialog-->
        <v-dialog persistent v-model="display.infoDialog" fullscreen>
            <v-card>
                <v-form v-model="formValid.editForm" :key="'editForm'+formRefreshKey">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td colspan="2" class="text-center">
                                        <template v-if="editMode">
                                            <div class="caption error--text">ボタンを押して、状態を切り替えることができます</div>
                                            <v-chip 
                                                @click="selectedRecordData.flags[prop] = !flag"
                                                class="ma-1" 
                                                :color="flag ? 'success' : 'error'"
                                                v-for="(flag, prop) in selectedRecordData.flags" :key="prop"
                                            >
                                                {{map.flag[prop]}} :
                                                <v-icon>
                                                    {{flag ? 'mdi-check' : 'mdi-close'}}
                                                </v-icon>
                                            </v-chip>
                                        </template>
                                        <template v-else>
                                            <v-chip 
                                                class="ma-1" 
                                                :color="flag ? 'success' : 'error'"
                                                v-for="(flag, prop) in selectedRecordData.flags" :key="prop"
                                            >
                                                {{map.flag[prop]}} :
                                                <v-icon>
                                                    {{flag ? 'mdi-check' : 'mdi-close'}}
                                                </v-icon>
                                            </v-chip>
                                        </template>
                                    </td>
                                </tr>
                                <tr>
                                    <td>時間(始)</td>
                                    <td>
                                        <div v-if="editMode">
                                            <date-time-picker 
                                                :view="display.dateTimePicker"
                                                :targetProp="'recordStart'" 
                                                :data="selectedRecordData"
                                                @syncSelectedRecord="syncSelectedRecord"
                                            />
                                        </div>
                                        <div v-else>
                                            {{selectedRecordData.recordStart}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>時間(終)</td>
                                    <td>
                                         <div v-if="editMode">
                                            <date-time-picker 
                                                :view="display.dateTimePicker"
                                                :targetProp="'recordEnd'" 
                                                :data="selectedRecordData"
                                                @syncSelectedRecord="syncSelectedRecord"
                                            />
                                        </div>
                                        <div v-else>
                                            {{selectedRecordData.recordEnd}}
                                        </div>
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>稼働時間</td>
                                    <td>
                                        <div v-if="editMode" class="caption">
                                            申請確定すると自動に再計算します
                                        </div>
                                        <div v-else>
                                            {{$gf.convertTime(selectedRecordData.totalTime)}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>現場(始)</td>
                                    <td>
                                        <div v-if="editMode">
                                            <v-select
                                                outlined dense
                                                :hide-details="true"
                                                :items="genbaKoushuData.genbaInfo"
                                                item-text="genbaName"
                                                item-value="genbaCode"
                                                v-model="selectedRecordData.recordGenbaStart"
                                                :rules="[v=> !!v || '必須項目です']"
                                            > 
                                            </v-select>
                                        </div>
                                        <div v-else>
                                            {{map.genba[selectedRecordData.recordGenbaStart]}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>現場(終)</td>
                                    <td>
                                        <div v-if="editMode">
                                            <v-select
                                                outlined dense
                                                :hide-details="true"
                                                :items="genbaKoushuData.genbaInfo"
                                                item-text="genbaName"
                                                item-value="genbaCode"
                                                v-model="selectedRecordData.recordGenbaEnd"
                                                :rules="[v=> !!v || '必須項目です']"
                                            > 
                                            </v-select>
                                        </div>
                                        <div v-else>
                                            {{map.genba[selectedRecordData.recordGenbaEnd]}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>工種</td>
                                    <td>
                                        <div v-if="editMode">
                                            <v-select
                                                outlined dense
                                                :hide-details="true"
                                                :items="genbaKoushuData.koushuInfo"
                                                item-text="koushuName"
                                                item-value="koushuCode"
                                                v-model="selectedRecordData.recordKoushu"
                                                :rules="[v=> !!v || '必須項目です']"
                                            > 
                                            </v-select>
                                        </div>
                                        <div v-else>
                                            {{map.koushu[selectedRecordData.recordKoushu]}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-center">休憩・移動</td>
                                </tr>
                                <tr v-if="editMode">
                                    <td colspan="2">
                                        <v-btn
                                            @click="display.newSubRecord = true"
                                            width="100%" color=success
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                            新規
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr :key="'subRecordTable'+subRecordTableKey">
                                    <td colspan="2" class="pa-0">
                                        <v-expansion-panels>
                                            <v-expansion-panel
                                                v-for="(subRecord, index) in selectedRecordData.subRecords"
                                                :key="index"
                                                class="pa-0"
                                            >
                                                <template v-if="!subRecord.deleted">
                                                    <v-expansion-panel-header>
                                                        {{$gf.convertLabel(subRecord.subRecordType)}}
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        
                                                            <v-btn 
                                                                v-if="editMode"
                                                                color=error width="100%"
                                                                @click="deleteSubRecord(selectedRecordData.subRecords,index)"
                                                            >
                                                                <v-icon>
                                                                    mdi-delete
                                                                </v-icon>
                                                                削除
                                                            </v-btn>
                                                            <v-simple-table> 
                                                                <template v-slot:default>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>時間(始)</td>
                                                                            <td>
                                                                                <div v-if="editMode">
                                                                                    <date-time-picker 
                                                                                        :view="display.dateTimePicker"
                                                                                        :targetProp="'subRecordStart'" 
                                                                                        :data="subRecord"
                                                                                        @syncSelectedRecord="syncSelectedRecord"
                                                                                    />
                                                                                </div>
                                                                                <div v-else>
                                                                                    {{subRecord.subRecordStart}}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>時間(終)</td>
                                                                            <td>
                                                                                <div v-if="editMode">
                                                                                    <date-time-picker 
                                                                                        :view="display.dateTimePicker"
                                                                                        :targetProp="'subRecordEnd'" 
                                                                                        :data="subRecord"
                                                                                        @syncSelectedRecord="syncSelectedRecord"
                                                                                    />
                                                                                </div>
                                                                                <div v-else>
                                                                                    {{subRecord.subRecordEnd}}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <template 
                                                                            v-if="$gf.convertLabel(subRecord.subRecordType)==='移動'"
                                                                        >
                                                                            <tr>
                                                                                <td>現場(始)</td>
                                                                                <td>
                                                                                    <div v-if="editMode" >
                                                                                        <v-select
                                                                                            style="max-width:45vw"
                                                                                            outlined dense
                                                                                            :hide-details="true"
                                                                                            :items="genbaKoushuData.genbaInfo"
                                                                                            item-text="genbaName"
                                                                                            item-value="genbaCode"
                                                                                            v-model="subRecord.subRecordStartGenba"
                                                                                            :rules="[v=> !!v || '必須項目です']"
                                                                                        > 
                                                                                        </v-select>
                                                                                    </div>
                                                                                    <div v-else>
                                                                                        {{map.genba[subRecord.subRecordStartGenba]}}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>現場(終)</td>
                                                                                <td>
                                                                                    <div v-if="editMode">
                                                                                        <v-select
                                                                                            style="max-width:45vw"
                                                                                            outlined dense
                                                                                            :hide-details="true"
                                                                                            :items="genbaKoushuData.genbaInfo"
                                                                                            item-text="genbaName"
                                                                                            item-value="genbaCode"
                                                                                            v-model="subRecord.subRecordEndGenba"
                                                                                            :rules="[v=> !!v || '必須項目です']"
                                                                                        > 
                                                                                        </v-select>
                                                                                    </div>
                                                                                    <div v-else>
                                                                                        {{map.genba[subRecord.subRecordEndGenba]}}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                    </v-expansion-panel-content>
                                                 </template>
                                                 <div v-else-if="editMode" class="px-6 py-3 text-center">
                                                    <span class="error--text mx-3" style="text-decoration: line-through;">
                                                        {{$gf.convertLabel(subRecord.subRecordType)}}
                                                    </span>
                                                    <v-btn
                                                        color=warning
                                                        @click="recoverSubRecord(selectedRecordData.subRecords,index)"
                                                    >
                                                        <v-icon>mdi-restart</v-icon>
                                                        元に戻す
                                                    </v-btn>
                                                 </div>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </td>
                                </tr>
                                <tr v-if="editMode">
                                    <td colspan="2">
                                        <v-textarea
                                            :rules="[v=> !!v || '理由を書いてください']"
                                            v-model="selectedRecordData.reason"
                                            outlined
                                            class="pt-3"
                                            name="input-7-4"
                                            label="理由(必須)"
                                            value="間違いがありました"
                                        ></v-textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-card-actions class="d-flex flex-column">
                        <v-btn v-if="!editMode" color="primary" class="ma-1" width="95%" @click="editMode = true">
                            <v-icon>mdi-pencil</v-icon>
                            編集
                        </v-btn>
                        <v-btn v-else
                            :disabled="!formValid.editForm"
                            color="success" class="ma-1" width="95%" @click="submitShinsei">
                            編集した内容で申請
                        </v-btn>
                        <v-btn v-if="editMode" color="error" class="ma-1" width="95%" @click="editMode = false">
                            編集モードを解除する
                        </v-btn>
                        <v-btn v-if="editMode"
                            :disabled="!formValid.editForm"
                            color="error" class="ma-1" width="95%" @click="submitShinsei(true)">
                            削除申請
                        </v-btn>
                        <v-btn color="error" class="ma-1" width="95%" @click="display.infoDialog = false">
                            閉じる
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <!--New Subrecord-->
        <v-dialog persistent fullscreen v-model="display.newSubRecord">
            <v-card :key="'newSubRecord'+this.formRefreshKey">
                <v-card-actions class="d-flex flex-column">
                    <v-btn
                        :disabled ="!subRecordForm.valid"
                        color=success width=100%
                        @click="addNewSubRecord"
                    >
                        確定
                    </v-btn>
                    <v-btn
                        class="ma-3"
                        color=error width=100%
                        @click="display.newSubRecord = false"
                    >
                        キャンセル
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-form v-model="subRecordForm.valid">
                        <v-select
                            label="区分"
                            v-model="formData.subRecordType"
                            :items="subRecordForm.type"
                        >
                        </v-select>
                        <v-divider class="my-2"/>
                        <date-time-picker 
                            label="開始時間"
                            :view="display.dateTimePicker"
                            :targetProp="'subRecordStart'" 
                            :data="formData"
                            @syncSelectedRecord="syncSelectedRecord"
                        />
                        <v-divider class="my-2"/>
                        <date-time-picker 
                            label="終了時間"
                            :view="display.dateTimePicker"
                            :targetProp="'subRecordEnd'" 
                            :data="formData"
                            @syncSelectedRecord="syncSelectedRecord"
                        />
                        <template v-if="$gf.convertLabel(formData.subRecordType)  === '移動'">
                            <v-divider class="my-2"/>
                            <v-select
                                label="開始現場"
                                outlined dense
                                :hide-details="true"
                                :items="genbaKoushuData.genbaInfo"
                                item-text="genbaName"
                                item-value="genbaCode"
                                v-model="formData.subRecordStartGenba"
                                :rules="[ (v)=> !!v || '現場を選んでください']"
                            > 
                            </v-select>
                            <v-divider class="my-2"/>
                            <v-select
                                label="終了現場"
                                outlined dense
                                :hide-details="true"
                                :items="genbaKoushuData.genbaInfo"
                                item-text="genbaName"
                                item-value="genbaCode"
                                v-model="formData.subRecordEndGenba"
                                :rules="[ (v)=> !!v || '現場を選んでください']"
                            > 
                            </v-select>
                            <v-divider class="my-2"/>
                            <v-select
                                label="工種"
                                outlined dense
                                :hide-details="true"
                                :items="genbaKoushuData.koushuInfo"
                                item-text="koushuName"
                                item-value="koushuCode"
                                v-model="formData.subRecordStartKoushu"
                                :rules="[ (v)=> !!v || '工種を選んでください']"
                            > 
                            </v-select>
                        </template>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import DateTimePicker from '@/components/Forms/DateTimePicker.vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
export default {
    props:['user','queryMode'],
    components:{
        DateTimePicker
    },
    data(){
        return{
            formRefreshKey:0,
            subRecordTableKey:0,
            formValid:{
                dateSubmit:false,
                editForm:false
            },
            menuModel:{
                menuDate1:false,
                menuDate2:false,
            },
            display:{
                infoDialog:false,
                dateTimePicker:false,
                newSubRecord:false
            },
            selectedRecordData:null,
            recordData:null,
            queryDate:{
                from: null,
                to:null
            },
            genbaKoushuInit:false,
            genbaKoushuData:null,
            map:{
                genba:{},
                koushu:{},
                flag:{
                    "kyuuShutsu":"休出",
                    "yuuKyuu":"有休",
                    "kekKin":"欠勤",
                    "kouKyuu":"公休",
                    "daiKyuu":"代休",
                    "furiKae":"振替",
                }
            },
            editMode:false,
            subRecordForm:{
                valid:false,
                type:[
                    { text:"休憩", value:[true,true,false]},
                    { text:"移動", value:[true,false,true]} 
                ]
            },
            formData:{
                locationData:{start:null,end:null},
                subRecordType:[true,true,false],
                subRecordStartKoushu:null,
                subRecordEndKoushu:null,
                subRecordStartGenba:null,
                subRecordEndGenba:null,
                subRecordStart:dayjs().format('YYYY-MM-DD HH:mm'),
                subRecordEnd:dayjs().format('YYYY-MM-DD HH:mm')
            }
        }
    },
    mounted(){
        if(this.user && !this.queryMode)
            this.init()
    },
    methods:{
        async submitShinsei(deleteMode = false){
            //Remove deleted Subrecords
            this.selectedRecordData.subRecords = this.selectedRecordData.subRecords.filter(subRecord=>{
                if(!subRecord.deleted)
                    return subRecord
            })
            //Recalculate record totalTime
            this.selectedRecordData.totalTime = dayjs(this.selectedRecordData.recordEnd).diff(dayjs(this.selectedRecordData.recordStart), 'ms') 
            if(deleteMode === true) this.selectedRecordData.shinseiType = 3
            let shinSeiForm = {
                userId : this.user,
                originalRecordId : this.selectedRecordData.recordId,
                editedRecord : this.selectedRecordData,
                reason: this.selectedRecordData.reason,
                shinseiType : this.selectedRecordData.shinseiType ? this.selectedRecordData.shinseiType : 1
            }
            await this.$store.dispatch('post',{url:'/api/submitShinsei', showUI:true, rawData:shinSeiForm})
            this.editMode = false
            this.display.infoDialog = false
        },
        addNewSubRecord(){
            this.formData.subTotalTime = dayjs(this.formData.subRecordEnd).diff(dayjs(this.formData.subRecordStart), 'ms') 
            this.formData.subId = uuidv4()
            this.formData.subRecordEndKoushu = this.formData.subRecordStartKoushu
            this.selectedRecordData.subRecords.push(JSON.parse(JSON.stringify( this.formData )))
            this.display.newSubRecord = false
            this.subRecordTableKey += 1
        },
        deleteSubRecord(subRecords, index){
            subRecords[index].deleted = true
            this.subRecordTableKey += 1
        },
        recoverSubRecord(subRecords, index){
            delete subRecords[index].deleted
            this.subRecordTableKey += 1
        },
        syncSelectedRecord(e){
            e.original[e.prop] = e.newValue
            this.formRefreshKey += 1
        },
        getGenbaKoushuInfo(){
            return new Promise(async(resolve, reject)=>{
                let response = await this.$store.dispatch('get', {url:'/api/getGenbaKoushu', showUI:true})
                if(response.result){
                    this.genbaKoushuData = response.result
                    this.generateGenbaKoushuMap(this.genbaKoushuData)
                    resolve()
                }
            })
        },
        generateGenbaKoushuMap(genbaKoushuData){
            genbaKoushuData.genbaInfo.forEach(genba => {
                this.map.genba[genba.genbaCode] = genba.genbaName
            });
            genbaKoushuData.koushuInfo.forEach(koushu => {
                this.map.koushu[koushu.koushuCode] = koushu.koushuName
            });
            this.genbaKoushuInit = true
        },
        async initNewRecordDialog(){
            if(!this.genbaKoushuInit) await this.getGenbaKoushuInfo()
            this.selectedRecordData = {
                flags:{
                    kyuuShutsu:false,
                    yuuKyuu:false,
                    kekKin:false,
                    kouKyuu:false,
                    daiKyuu:false,
                    furiKae:false
                },
                lastEdited: null,
                locationData:{
                    start:null,
                    end:null
                },
                recordEnd: dayjs().format('YYYY-MM-DD HH:mm'),
                recordGenbaEnd: null,
                recordGenbaStart: null,
                recordId: null,
                recordKoushu: null,
                recordStart: dayjs().format('YYYY-MM-DD HH:mm'),
                recordType:[true,false,false],
                subRecords: [],
                shinseiType : 2
            }
            this.editMode = true
            this.display.infoDialog = true
        },
        async initInfoDialog(recordData){
            if(!this.genbaKoushuInit) await this.getGenbaKoushuInfo()
            this.selectedRecordData = recordData
            this.formData.subRecordStart = recordData.recordStart
            this.formData.subRecordEnd = recordData.recordEnd
            this.display.infoDialog = true
        },
        async init(){
            if(!this.queryMode)
                this.initThisMonth()

            let response = await this.$store.dispatch('get', {
                url:'/api/getUserRecord?uid='+this.user+'&f='+this.queryDate.from+'&t='+this.queryDate.to,
                showUI:true
            })
            if(response.result){
                this.recordData = response.result.sort((a, b) => (a.recordStart > b.recordEnd) ? 1 : -1)
            }
                
        },
        initThisMonth(){
            let currentDate = new Date()
            let reconstructedDate = new Date(currentDate.getFullYear(),(Number(currentDate.getMonth())+1),0 )
            this.queryDate.from = reconstructedDate.getFullYear()+"-"+( Number(reconstructedDate.getMonth()+1)<10? "0"+Number(reconstructedDate.getMonth()+1): Number(reconstructedDate.getMonth()+1) )+"-01"
            this.queryDate.to = reconstructedDate.getFullYear()+"-"+( Number(reconstructedDate.getMonth()+1)<10? "0"+Number(reconstructedDate.getMonth()+1): Number(reconstructedDate.getMonth()+1) )+"-"+reconstructedDate.getDate()
        },
    }
}
</script>