<template>
    <div v-if="memberData && loaded">
        <v-text-field label="検索" v-model="search"> </v-text-field>
        <v-data-table
            :search="search"
            :headers="headers"
            :items="memberData"
            :mobile-breakpoint="noBreak ? 0 : 600"
            :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': '表示数',
                'items-per-page-options': [10, 50, -1],
            }"
        >
            <template v-slot:header.select="{ header }">
                <div class="d-flex align-center">
                    全選択
                    <v-checkbox v-model="selectAllUsers" @change="selectAll" />
                </div>
            </template>
            <template v-slot:item.select="{ item }">
                <v-checkbox
                    v-model="selectedUsers"
                    :value="item.userInfo._id"
                />
            </template>

            <template v-slot:item.userStatus="{ item }">
                <v-chip
                    :color="
                        mapData.status[JSON.stringify(item.userStatus)].color
                    "
                >
                    {{ mapData.status[JSON.stringify(item.userStatus)].label }}
                </v-chip>
            </template>
            <template v-slot:item.userGenba="{ item }">
                {{
                    item.userInfo.userGenba
                        ? mapData.genba[item.userInfo.userGenba]
                        : "なし"
                }}
            </template>
            <template v-slot:item.userKoushu="{ item }">
                {{
                    item.userInfo.userKoushu
                        ? mapData.koushu[item.userInfo.userKoushu]
                        : "なし"
                }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        headerSelect: {
            type: Boolean,
            default: false,
        },
        noBreak: {
            type: Boolean,
            default: false,
        },
        headerStatus: {
            type: Boolean,
            default: true,
        },
        headerName: {
            type: Boolean,
            default: true,
        },
        headerGenbaKoushu: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        loaded:false,
        selectAllUsers: false,
        selectedUsers: [],
        search: "",
        memberData: null,
        headers: null,
        mapData: {},
        gData:{
            genba:[], koushu:[]
        }
    }),
    async mounted() {

        this.memberData = await this.getMemberList();
        await this.generateHeaders();
        await this.generateMaps();
        this.loaded = true
    },
    watch: {
        selectedUsers: {
            handler() {
                this.$emit("selected", this.selectedUsers);
            },
            deep: true,
        },
    },
    methods: {
        async getGeneralInfo() {
            this.gData.genba = await this.$get.getGenba();
            this.gData.koushu = await this.$get.getKoushu();
        },
        selectAll() {
            if (this.selectAllUsers) {
                this.selectedUsers = [];
                this.memberData.forEach((member) => {
                    this.selectedUsers.push(member.userInfo._id);
                });
            } else this.selectedUsers = [];
        },
        async generateHeaders() {
            let tmpHeader = [];
            if (this.headerSelect) {
                tmpHeader.push({
                    sortable:false,
                    text: "選択",
                    value: "select",
                });
            }
            if (this.headerName) {
                tmpHeader.push({ text: "名前", value: "userInfo.name" });
            }

            if (this.headerStatus) {
                tmpHeader.push({ text: "状況", value: "userStatus" });
            }
            if (this.headerGenbaKoushu) {
                tmpHeader.push({ text: "現場", value: "userGenba" });
                tmpHeader.push({ text: "工種", value: "userKoushu" });
            }
            this.headers = tmpHeader;
        },
        async getMemberList() {
            const response = await this.$store.dispatch("get", {
                url: "/api/memberlist?tid=" + this.$store.state.userProfile.team.id,
                showUI: true,
            });
            if (response && response.result) return response.result;
        },
        async generateMaps() {
            if (this.headerStatus) {
                this.mapData.status = {};
                this.mapData.status[JSON.stringify([false, false, false])] = {
                    label: "退勤中",
                    color: "red",
                };
                this.mapData.status[JSON.stringify([true, false, false])] = {
                    label: "出勤中",
                    color: "green",
                };
                this.mapData.status[JSON.stringify([true, true, false])] = {
                    label: "休憩中",
                    color: "yellow",
                };
                this.mapData.status[JSON.stringify([true, false, true])] = {
                    label: "移動中",
                    color: "blue",
                };
            }
            if (this.headerGenbaKoushu) {
                this.mapData.genba = {}
                this.mapData.koushu = {}
                await this.getGeneralInfo()
                this.gData.genba.forEach(genba => {
                    this.mapData.genba[genba.genbaCode] = genba.genbaName
                });
                this.gData.koushu.forEach(koushu => {
                    this.mapData.koushu[koushu.koushuCode] = koushu.koushuName
                });
            }
        },
    },
};
</script>
