<template>
	<div style="width: 100%">
		<div class="d-flex justify-end">
			<v-btn
				class="primary ma-1"
				:disabled="isSelected[0]"
				@click="
					filterToday()
					selectedBtn = 1
				"
				>今日</v-btn
			>
			<v-btn
				class="primary ma-1"
				:disabled="isSelected[1]"
				@click="
					filterThisWeek()
					selectedBtn = 2
				"
				>今週</v-btn
			>
			<v-btn
				class="primary ma-1"
				:disabled="isSelected[2]"
				@click="
					filterThisMonth()
					selectedBtn = 3
				"
				>今月</v-btn
			>
		</div>
		<v-data-table
			v-if="teamMemberRecordData"
			:headers="headers"
			:items="teamMemberRecordData"
			no-data-text="データがありません"
			:headerProps="{
				sortByText:'並び'
			 }"
			:footer-props="{
				itemsPerPageOptions: [10, 50, 100, -1],
				itemsPerPageAllText: '全部',
				itemsPerPageText: 'アイテム数',
			}"
			class="elevation-1"
		>
			<template v-slot:item.records="{ item }">
				{{ (item.records / 1000 / 60 / 60).toFixed(1) }} 時間
			</template>
		</v-data-table>
	</div>
</template>

<script>
import dayjs from "dayjs"
export default {
	data: () => ({
		headers: [
			{
				text: "名前",
				align: "start",
				value: "userInfo.name",
			},
			{ text: "勤務時間", value: "records" },
		],
		selectedBtn: 1, //1:Day, 2:Week, 3:Month
		teamMemberRecordData: null,
		rawData: null,
	}),
	mounted() {
		this.init()
	},
	computed: {
		isSelected() {
			switch (this.selectedBtn) {
				case 1:
					return [true, false, false]
				case 2:
					return [false, true, false]
				case 3:
					return [false, false, true]
			}
		},
	},
	methods: {
		async init() {
			try {
				this.$store.commit("setLoadingScreen", { display: true })
                const response = await this.$store.dispatch("post", {
                    url: `/api/teamMemberRecord?`,
                    rawData:{
						teamId: this.$store.state.userProfile.team.id,
						leaderId: this.$store.state.userProfile.id,
					},
                });
                if (!response && !response.result) return
				this.rawData = response.result
				this.filterToday()
				this.$store.commit("setLoadingScreen", { display: false })
			} catch (err) {
				if (!this.$store.state.isProduction) console.log(err)
				this.$store.commit("setSnack", {
					display: true,
					iconColor: "red",
					icon: "mdi-alert-circle",
					text: err.response
						? err.response.data
						: "エラーが発生しました。",
				})
				this.$store.commit("setLoadingScreen", { display: false })
			}
		},
		filterToday() {
			let localData = JSON.parse(JSON.stringify(this.rawData))
			localData.forEach((user, userIndex) => {
				localData[userIndex].records = user.records.filter((e) => {
					if (
						dayjs(e.recordStart, "YYYY-MM-DD HH:mm:ss").isSame(
							dayjs().format("YYYY-MM-DD HH:mm:ss"),
							"d"
						)
					)
						return true
				})
				//Calculate total work time
				if (localData[userIndex].records.length >= 2) {
					localData[userIndex].records = localData[
						userIndex
					].records.reduce((a, b) => {
						if ((a > 0 || a.totalTime) && b.totalTime)
							return isNaN(a)
								? a.totalTime + b.totalTime
								: a + b.totalTime
						else return 0
					})
				} else {
					localData[userIndex].records = localData[userIndex].records
						.length
						? localData[userIndex].records[0].totalTime
						: 0
				}
			})
			this.teamMemberRecordData = localData
		},
		filterThisWeek() {
			let localData = JSON.parse(JSON.stringify(this.rawData))
			localData.forEach((user, userIndex) => {
				localData[userIndex].records = user.records.filter((e) => {
					if (
						dayjs(e.recordStart, "YYYY-MM-DD HH:mm:ss").isSame(
							dayjs().format("YYYY-MM-DD HH:mm:ss"),
							"week"
						)
					)
						return true
				})
				//Calculate total work time
				if (localData[userIndex].records.length >= 2) {
					localData[userIndex].records = localData[
						userIndex
					].records.reduce((a, b) => {
						if ((a > 0 || a.totalTime) && b.totalTime)
							return isNaN(a)
								? a.totalTime + b.totalTime
								: a + b.totalTime
						else return 0
					})
				} else {
					localData[userIndex].records = localData[userIndex].records
						.length
						? localData[userIndex].records[0].totalTime
						: 0
				}
			})
			this.teamMemberRecordData = localData
			return localData
		},
		filterThisMonth() {
			let localData = JSON.parse(JSON.stringify(this.rawData))
			localData.forEach((user, userIndex) => {
				localData[userIndex].records = user.records.filter((e) => {
					if (
						dayjs(e.recordStart, "YYYY-MM-DD HH:mm:ss").isSame(
							dayjs().format("YYYY-MM-DD HH:mm:ss"),
							"month"
						)
					)
						return true
				})
				//Calculate total work time
				if (localData[userIndex].records.length >= 2) {
					localData[userIndex].records = localData[
						userIndex
					].records.reduce((a, b) => {
						if ((a > 0 || a.totalTime) && b.totalTime)
							return isNaN(a)
								? a.totalTime + b.totalTime
								: a + b.totalTime
						else return 0
					})
				} else {
					localData[userIndex].records = localData[userIndex].records
						.length
						? localData[userIndex].records[0].totalTime
						: 0
				}
			})
			this.teamMemberRecordData = localData
			return localData
		},
	},
}
</script>

<style scoped>
.customTable td {
	border: 1px solid grey;
}
</style>
