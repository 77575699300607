<template>
    <v-dialog v-model="displayModel" fullscreen persistent>
        <v-sheet style="height:100%;" class="pa-4 mb-4 teamMenu">
             <!--Main -->
            <div v-if="!componentActivated">
                <div class="font-weight-bold display-1">
                    チーム管理画面
                </div>
                <div class="menuPanel">
                    <v-btn 
                        width="100%"
                        class="primary my-2 text-h5"
                        v-for="item in menu" :key="item.component" 
                        @click="switchComponent(item)"
                    >
                        {{item.label}}
                    </v-btn>
                </div>
            </div>
            <!--Component -->
            <div v-else >
                <div class="font-weight-bold display-1" style="text-align:center">
                    {{selectedItem.label}}
                </div>
                <div class="menuPanel">
                   <component :is="selectedItem.component" /> 
                </div>
            </div>
        </v-sheet>
        <v-btn 
            v-if="!componentActivated"
            dark
            style="width:100%; position:fixed; bottom:0;"
            color="red"
            @click="$emit('closeteamview')"
        >
            閉じる
        </v-btn>
        <v-btn 
            v-else
            dark
            style="width:100%; position:fixed; bottom:0;"
            color="warning"
            @click="componentActivated = false"
        >
            戻る
        </v-btn>
    </v-dialog>    
</template>

<script>
import TeamMemberOt from '../../components/leaderComponent/teamMemberOt.vue'
import MemberStatus from './TeamViewPanels/MemberStatus.vue'
export default {
    props:["displayModel"],
    components:{
        TeamMemberOt,
        MemberStatus,
    },
    data:()=>({
        componentActivated:false,
        selectedItem:null,
        menu:[
            {label:"勤務時間一覧", component:"TeamMemberOt"},
            {label:"勤務状況一覧", component:"MemberStatus"},
        ]
    }),
    methods:{
        switchComponent(item){
            this.componentActivated = true
            this.selectedItem = item
        }
    }
}
</script>

<style scoped>
.teamMenu{
    display: grid;
    justify-content: center;
    overflow-y:scroll;
}
.menuPanel{
    display: grid;
    height: 100%;
    align-content: center;
}
</style>


